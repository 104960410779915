<template>
  <div class="legal-privat legal-doc-view">
    <p><strong>Datenschutzerklärung</strong><br>
      der Individuallist UG (haftungsbeschränkt)<br>
      (Stand 22.07.2019)</p>

    <p>Im Rahmen unserer Datenschutzerklärung informieren wir unsere Kunden und die Besucher unseres Internetauftritts über die Erhebung und Verarbeitung personenbezogener Daten durch uns. Personenbezogene Daten sind alle Daten, die auf eine identifizierte oder identifizierbare natürliche Person beziehbar sind, zum Beispiel Namen, Adressen, E-Mail-Adressen, aber auch das Nutzerverhalten.</p>

    <p><strong>1. Wer ist für die Datenverarbeitung verantwortlich?</strong></p>

    <p>Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DS-GVO) ist die</p>

    <p>Individuallist UG (haftungsbeschränkt)<br>
      Mettmanner Straße 11<br>
      40233 Düsseldorf<br>
      Deutschland<br>
      Telefon: 0211 977 158 57<br>
      E-Mail: <a href="mailto:arkadijbronstejn@gmail.com" target="_blank">arkadijbronstejn@gmail.com</a></p>

    <p>Bei allen Fragen zum Datenschutz können Sie sich direkt an uns wenden.</p>

    <p><strong>2. Auf welcher Rechtsgrundlage verarbeiten wir personenbezogene Daten?</strong></p>

    <p>Wir verarbeiten personenbezogenen Daten nach den Vorgaben der Europäischen Datenschutz-Grundverordnung (DS-GVO) und dem Bundesdatenschutzgesetzes (BDSG).</p>

    <p>Die Verarbeitung der Daten kann nach Art. 6 DS-GVO erfolgen<br>
      - auf Basis einer Einwilligung,<br>
      - zur Erfüllung von vertraglichen Pflichten oder vorvertraglichen Maßnahmen,<br>
      - zur Erfüllung einer rechtlichen Verpflichtung, der der Verantwortliche unterliegt,<br>
      - im öffentlichen Interesse oder<br>
      - soweit die Verarbeitung zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich ist, sofern nicht die Interessen und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen. Ein berechtigtes Interesse liegt insbesondere bei der Begründung eines Vertrages mit wirtschaftlichen Pflichten, etwa bei einer kostenpflichtigen Erbringung von Dienstleistungen, oder einem Verkauf von Waren, vor.</p>

    <p><strong>3. Welche Daten werden von uns bei Besuch unseres Internetauftritts erhoben und verarbeitet?</strong></p>

    <p>Bei einem informatorischen Besuch unseres Internetauftritts, also wenn Sie sich weder registrieren noch uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt (die sogenannten „Server-Logfiles"). Die folgenden Daten sind für uns technisch erforderlich, um Ihnen unsere Internetseiten anzuzeigen:</p>

    <p>- die IP-Adresse<br>
      - das Datum und Uhrzeit der Anfrage<br>
      - die Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br>
      - der genaue Inhalt der Anforderung (die konkrete Seite)<br>
      - der Zugriffsstatus/HTTP-Statuscode<br>
      - die jeweils übertragene Datenmenge<br>
      - die Quelle/Internetseite, von welcher die Anforderung kommt<br>
      - der von Ihnen eingesetzte Browser<br>
      - das eingesetzte Betriebssystem und dessen Oberfläche<br>
      - die Sprache und Version Ihrer Browsersoftware.</p>

    <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 f) DS-GVO zur Gewährleistung eines reibungslosen Verbindungsaufbaus unserer Internetseiten, zur Gewährleistung einer komfortablen Nutzung unserer Internetseiten, zur Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken.</p>

    <p>In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.</p>

    <p><strong>4. Welche Daten werden von uns bei Nutzung unseres Internetauftritts erhoben verarbeitet und weitergegeben?</strong></p>

    <p>Die nachfolgenden Punkte geben Ihnen einen Überblick darüber, an welchen Stellen und zu welchem Zweck die Individuallist UG (haftungsbeschränkt) Daten von Nutzern erhebt, verarbeitet und nutzt:</p>

    <p>a) personenbezogene Seite im sozialen Netzwerk<br>
      Personenbezogene Daten werden im sozialen Netzwerk über Ihre Personenseite im Umfang der von Ihnen gewählten Einstellungen erhoben und an Dritte weitergegeben.</p>

    <p>b) personenbezogene Seite im sozialen Netzwerk auf der Unterseite Dating<br>
      Personenbezogene Daten werden im sozialen Netzwerk auf der Unterseite Dating über Ihre Personenseite im Umfang der von Ihnen gewählten Einstellungen erhoben und an Dritte weitergegeben.</p>

    <p>c) personenbezogene Seite im sozialen Netzwerk auf der Unterseite Beruf<br>
      Personenbezogene Daten werden im sozialen Netzwerk auf der Unterseite Beruf über Ihre Personenseite im Umfang der von Ihnen gewählten Einstellungen erhoben und an Dritte weitergegeben.</p>

    <p>d) Verkaufsplattform<br>
      Personenbezogene Daten werden bei Benutzung der Verkaufsplattform im Rahmen der Erstellung der Angebote und der Verkaufsabwicklung erhoben und an Dritte weitergegeben.</p>

    <p>Auf den Umgang Dritter mit von Ihnen überlassenen Daten hat die Individuallist UG (haftungsbeschränkt) keinen Einfluss.</p>

    <p>e) Anzeige von Werbung<br>
      Die Individuallist UG (haftungsbeschränkt) verwendet Ihre personenbezogenen Daten ferner im Rahmen der Anzeige von Werbung. Die Auswahl der angezeigten Werbung erfolgt unter Auswertung der von Ihnen zur Verfügung gestellten Daten.</p>

    <p>Ihre personenbezogenen Daten werden dabei nicht an Dritte weitergegeben<br>
      Zugleich nutzen wir Ihre personenbezogenen Daten, soweit Sie dem ausdrücklich zugestimmt haben, im Rahmen von statistischen Auswertungen zur Optimierung unseres Angebotes.</p>

    <p><strong>5. Wie werden Daten bei Anfragen oder Mitteilungen per Post, per Fax, per E-Mail oder über ein Kontaktformular von uns erhoben und verarbeitet?</strong></p>

    <p>Wenn Sie uns Anfragen oder Mitteilungen per Post, per Fax, per E-Mail oder über ein Kontaktformular schicken, welche sich auf Bestellungen beziehen, speichern wir Ihre Anfrage oder Mitteilung sowie unsere Antwort im Rahmen unserer handels- und steuerrechtlichen Aufbewahrungspflichten als Korrespondenz zur jeweiligen Bestellung bzw. zu Ihrem Kundenkonto ab.</p>

    <p>Bei anderen Anfragen oder Mitteilungen per Post, per Fax, per E-Mail oder über ein Kontaktformular nutzen wir Ihre in der Anfrage mitgeteilten personenbezogenen Daten ausschließlich zur Beantwortung Ihrer Anfrage, speichern Ihre Anfrage und in der Anfrage mitgeteilte personenbezogenen Daten anschließend aber nicht ab.</p>

    <p><strong>6. Wie lange werden personenbezogene Daten bei uns gespeichert?</strong></p>

    <p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist. Insbesondere ergeben sich Verpflichtungen aus dem Handels- und Steuerrecht, Daten aus den abgeschlossenen Geschäften für die Dauer der gesetzlichen Aufbewahrungsfristen zur archivieren. Rechtsgrundlage für die entsprechenden Datennutzungen ist Art. 6 Abs. 1 c) DS-GVO.</p>

    <p>Nach Ablauf der jeweiligen gesetzlichen Aufbewahrungsfrist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Fortsetzung einer Speicherung fortbesteht.</p>

    <p><strong>7. Welche Rechte stehen von der Datenverarbeitung betroffenen Personen zu?</strong></p>

    <p>Das geltende Datenschutzrecht gewährt unseren Kunden und den Besuchern unseres Internetauftritts gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</p>

    <p>a) Ein Recht auf Auskunft gemäß Art. 15 DS-GVO<br>
      Ihnen steht ein Recht zu, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, so steht Ihnen ein Recht auf Auskunft über diese personenbezogenen Daten und auf Informationen über<br>
      - die Verarbeitungszwecke,<br>
      - die Kategorien personenbezogener Daten, die verarbeitet werden,<br>
      - die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen,<br>
      - falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer,<br>
      - das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung,<br>
      - das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde,<br>
      - wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten oder<br>
      - das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling zu.</p>

    <p>b) Ein Recht auf Berichtigung gemäß Art. 16 DS-GVO<br>
      Ihnen steht ein Recht zu, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen.</p>

    <p>c) Ein Recht auf Löschung gemäß Art. 17 DS-GVO<br>
      Ihnen steht ein Recht zu, von uns unter den Voraussetzungen des Art. 17 Abs. 1 DS-GVO die Löschung Ihrer personenbezogenen Daten zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.</p>

    <p>d) Ein Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DS-GVO<br>
      Ihnen steht ein Recht zu, von uns die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, sofern<br>
      - die von Ihnen bestrittene Richtigkeit Ihrer Daten von uns überprüft wird,<br>
      - Sie eine Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen,<br>
      - wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie diese Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder<br>
      - wenn Sie Widerspruch aus Gründen, die sich aus Ihrer besonderen Situation ergeben, eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe überwiegen.</p>

    <p>e) Ein Recht auf Datenübertragbarkeit gemäß Art. 20 DS-GVO:<br>
      Ihnen steht ein Recht zu, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, sofern die Verarbeitung auf einer Einwilligung oder auf einem Vertrag beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>

    <p>f) Ein Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DS-GVO<br>
      Ihnen steht ein Recht zu, eine uns erteilte Einwilligung in die Verarbeitung ihrer personenbezogenen Daten jederzeit zu widerrufen. Es entstehen Ihnen keine besonderen Kosten (außer Übermittlungskosten nach den Basistarifen Ihres Providers).</p>

    <p>Durch den Widerruf Ihrer Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>

    <p>g) Ein Recht auf Beschwerde gemäß Art. 77 DS-GVO<br>
      Ihnen steht ein Recht auf Beschwerde bei einer zuständigen Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Datenschutz-Grundverordnung verstößt.</p>

    <p><strong>8. Welche Bereitstellung personenbezogener Daten ist erforderlich?</strong></p>

    <p>Unser Internetangebot ist als soziales Netzwerk einschließlich einer Verkaufsplattform auf eine Eingabe personenbezogener Daten ausgelegt. Die Entscheidung, uns personenbezogene Daten mitzuteilen, steht Ihnen frei.</p>

    <p>Die Verwendung und Weitergabe personenbezogener Daten an Dritte erfolgt nur dann, wenn die Daten von Ihnen gerade zu diesem Zweck zur Verfügung gestellt wurden.</p>

    <p><strong>9. Welche Einwilligungen haben Sie uns erteilt?</strong></p>

    <p>Eine Einwilligung zur Erhebung, Verarbeitung und Nutzung personenbezogener Daten im Rahmen unseres Internetangebots haben Sie gegebenenfalls elektronisch erklärt. Für Kontaktaufnahmen zum Zwecke der Werbung oder bestimmte Datennutzungen haben Sie uns gegebenenfalls Einwilligungen erteilt (beispielsweise zur Zusendung eines Newsletters oder zwecks Zusendung von Informationen nach Registrierung als Kunde).</p>

    <p>Wenn Sie uns eine Einwilligung erteilt haben, sind die Einwilligungstexte bei uns gespeichert und können abgerufen werden. Wenden Sie sich zum Abruf dieser Texte einfach per E-Mail an die unter Ziffer 1 dieser Datenschutzerklärung zu findende E-Mail-Adresse an uns. Wir übermitteln Ihnen die gewünschten Informationen sodann per E-Mail.</p>

    <p><strong>10. Verwendung von Cookies</strong></p>

    <p>Um unseren Internetauftritt ansprechend zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Bei Cookies handelt es sich um kleine Textdateien, welche auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Internetseite besuchen. Cookies dienen dazu, unser Internetangebot insgesamt nutzerfreundlicher und effektiver zu gestalten. Cookies richten auf Ihrem Endgerät keinen Schaden an, und enthalten keine Viren, Trojaner oder sonstige Schadsoftware.</p>

    <p>Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sogenannte transiente oder Sitzungs-Cookies).</p>

    <p>Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns oder unseren Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim nächsten Besuch wiederzuerkennen (sogenannte persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im einzeln festgelegten Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, wobei sich die Dauer bis zu Löschung je nach Cookie unterscheiden kann.</p>

    <p>Sofern durch einzelne von uns eingesetzte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gemäß Art. 6 Abs. 1 f) DS-GVO zur Wahrung unserer berechtigten Interessen an der bestmöglichen Funktionalität unseres Internetauftritts sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.</p>

    <p>Bitte beachten Sie, dass Sie Ihren Browser so einstellen können, dass Sie über jedes Setzen von Cookies informiert werden. Sie können Ihren Browser so einstellen, dass Sie über eine Annahme von Cookies entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen können. Die Festlegung der Cookie-Einstellungen erfolgt bei hierbei verschiedenen Browsern auf unterschiedliche Weise. Die Änderung der Einstellungen hinsichtlich der Annahme oder Ablehnung von Cookies ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen aufzeigt, wie Sie Ihre Cookie-Einstellungen ändern können. Auch könne Sie hinsichtlich des von Ihnen eingesetzten Browsers jederzeit eine Löschung der gespeicherten Cookies veranlassen.</p>

    <p>Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalität unserer Internetseiten eingeschränkt sein kann.</p>

    <p><strong>11. Bestellung unseres Newsletters</strong></p>

    <p>Mit Ihrer Einwilligung können Sie unseren Newsletter abonnieren, mit dem wir Sie über unsere aktuellen interessanten Angebote informieren. Die beworbenen Waren und Dienstleistungen sind in der Einwilligungserklärung benannt.</p>

    <p>Für die Anmeldung zu unserem Newsletter verwenden wir das sog. Double-Opt-in-Verfahren. Das heißt, dass wir Ihnen nach Ihrer Anmeldung eine E-Mail an die angegebene E-Mail-Adresse senden, in welcher wir Sie um Bestätigung bitten, dass Sie die Zusendung des Newsletters wünschen. Wenn Sie Ihre Anmeldung nicht bestätigen, werden Ihre Informationen gesperrt und nach einem Monat automatisch gelöscht. Darüber hinaus speichern wir jeweils Ihre eingesetzten IP-Adressen und Zeitpunkte der Anmeldung und Bestätigung. Zweck des Verfahrens ist, Ihre Anmeldung nachweisen und ggf. einen möglichen Missbrauch Ihrer persönlichen Daten aufklären zu können.</p>

    <p>Pflichtangabe für die Übersendung des Newsletters ist allein Ihre E-Mail-Adresse. Die Angabe weiterer, gesondert markierter Daten ist freiwillig und wird verwendet, um Sie persönlich ansprechen zu können.</p>

    <p>Nach Ihrer Bestätigung speichern wir Ihre E-Mail-Adresse zum Zweck der Zusendung des Newsletters. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 a) DS-GVO.</p>

    <p>Ihre Einwilligung in die Übersendung des Newsletters können Sie jederzeit widerrufen und den Newsletter abbestellen. Den Widerruf können Sie durch Klick auf den in jeder Newsletter-E-Mail bereitgestellten Link, oder per E-Mail an die unter Ziffer 1 dieser Datenschutzerklärung angegebene E-Mail-Adresse erklären.</p>

    <p><strong>12. Datensicherheit</strong></p>

    <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen einen unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>

    <p><strong>13. Keine Nutzung einer automatisierten Entscheidungsfindung</strong></p>

    <p>Hiermit sichern wir zu, dass wir keine automatische Entscheidungsfindung einschließlich Profiling vornehmen.</p>

    <p><strong>14. Information zum Recht auf Widerspruch gegen die Verarbeitung gemäß Art. 21 DS-GVO</strong></p>

    <p>Ihnen steht ein Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die durch uns aufgrund von Art. 6 Abs. 1 e) oder f) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>

    <p>Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>

    <p>Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>

    <p>Ihren Widerspruch senden Sie bitte formfrei an unsere zu Beginn unserer Datenschutzerklärung unter Ziffer 1 genannten Kontaktdaten.</p>
  </div>
</template>
